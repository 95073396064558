import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
// import { web3 } from "../../redux/actions/metamaskAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
// import { getDataAPI } from "../../utils/API";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Modal from "@material-ui/core/Modal";

import "./PoolDetail.css";
import PoolInformation from "./PoolInformation";
import AboutProject from "./AboutProject";
import TelegramIcon from "@material-ui/icons/Telegram";
import LanguageIcon from "@material-ui/icons/Language";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

//import img1 from "../../images/img1.png";
import txprogress from "../../images/loading.gif";
import { SeedifyFundsContractAbi } from "../abis";
import { connect, useSelector } from "react-redux";
import BusdIDOabi from "../busdIDO.json";
import tokenABI from "../tokenABI.json";
import Footer from "../Home/footer";
import igoLaunchpadABI from "../../helpers/igoLaunchpadABI.json";
import { actions } from "../../actions";
import Web3 from "web3";
import { web3Services } from "../../services/web3.service";
import { Button, Loading } from "../../theme/main.styled";
// const web3 = new Web3(
//   new Web3.providers.HttpProvider(
//     "https://data-seed-prebsc-1-s1.binance.org:8545"
//   )
// );
const { web3 } = web3Services;
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
      chainId: 56,
      network: "binance",
    },
  },
};
//Web3Modal.
const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});
// toast.configure();
const PoolDetail = (props) => {
  const { getSinglePoolDetail, singlePoolDetail, web3Data } = props;
  // const { web3Data? } = useSelector((state) => state);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // use state hooks to set and get values.
  // const [singlePoolDetail?, setPool_detail] = useState("");
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();
  const [amount, setAmount] = useState();
  const [whichtier, setwhichtier] = useState(0);
  const [txloader, settxloader] = useState(false);
  // const [checktierloader, setchecktierloader] = useState(false)
  const [minvalueone, setMinValueOne] = useState();
  const [minvaluetwo, setMinValueTwo] = useState();
  const [minvaluethree, setMinValueThree] = useState();
  const [minvaluefour, setMinValueFour] = useState();
  const [minvaluefive, setMinValueFive] = useState();
  const [minvaluesix, setMinValueSix] = useState();
  const [minvalueseven, setMinValueSeven] = useState();
  const [minvalueeight, setMinValueEight] = useState();
  const [minvaluenine, setMinValueNine] = useState();
  const [tokenAllowance, setTokenAllowance] = useState(0);
  const [userBalance, setUserBalance] = useState();
  const [totalTokenSupply, setTotalTokenSupply] = useState("");
  const [tierMaxLimit, setTierMaxLimit] = useState("");
  const [userdetails, setUserDetails] = useState({});
  const [totalPoolRaise, setTotalPoolRaise] = useState();
  //var checkvalid = 0;
  const id = useParams().id;
  const name = useParams().name;
  var y = 0;
  if (name === "upcomming") {
    y = 1;
  }

  useEffect(() => {
    // Getting res from backend api and setting to the setPool detail.
    // getDataAPI(`getPool/${id}`).then((res) => setPool_detail(res.data));
    getSinglePoolDetail(id);
  }, [id]);

  // var contractAddr = "";
  const [contractAddr, setContractAddr] = useState();
  useEffect(() => {
    if (singlePoolDetail?.phases[0].phaseContractAddress) {
      // contractAddr = web3.utils.toChecksumAddress(singlePoolDetail?.address);
      setContractAddr(singlePoolDetail?.phases[0].phaseContractAddress);
    }
  }, [singlePoolDetail]);

  // useEffect(() => {
  //   const checkTokensApproval = async () => {
  //     // const web3 = new Web3(provider);
  //     console.log("web3Data? address", web3Data?.address);
  //     // Get balance of connected address.
  //     if (web3 && web3Data?.address) {
  //       var balance = await web3.eth.getBalance(web3Data?.address);
  //       if (singlePoolDetail?.crypto_type === "BUSD" && web3Data?.address) {
  //         const tokenContractInstance = new web3.eth.Contract(
  //           tokenABI,
  //           web3.utils.toChecksumAddress(
  //             "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
  //           )
  //         );
  //         const result = web3.utils.fromWei(
  //           await tokenContractInstance.methods
  //             .allowance(web3Data?.address, contractAddr)
  //             .call()
  //         );
  //         setTokenAllowance(result);
  //         const totalSupply = web3.utils.fromWei(
  //           await tokenContractInstance.methods.totalSupply().call()
  //         );
  //         setTotalTokenSupply(totalSupply);
  //         balance = await tokenContractInstance.methods
  //           .balanceOf(web3Data?.address)
  //           .call();
  //       }
  //       setUserBalance(balance);
  //     }
  //   };
  //   checkTokensApproval();
  // }, [web3Data?.address, singlePoolDetail, contractAddr]);

  useEffect(() => {
    //Consuming smart contract ABI and contract address.
    async function simpleContract() {
      try {
        if (contractAddr) {
          const SimpleContract = new web3.eth.Contract(
            singlePoolDetail?.isPaymentTokenNative
              ? BusdIDOabi
              : igoLaunchpadABI,
            contractAddr
          );
          //Getting total bnb from blockchain
          let totalTokenFxn = !singlePoolDetail?.isPaymentTokenNative
            ? "totalBUSDReceivedInAllTier"
            : "totalBnbReceivedInAllTier";
          console.log(
            totalTokenFxn,
            SimpleContract.methods.totalBUSDReceivedInAllTier
          );
          const result = await SimpleContract.methods[totalTokenFxn]().call();
          //Getting max cap from blockchain
          console.log(web3.utils.fromWei(result));
          setTotalPoolRaise(+web3.utils.fromWei(result));
        }
      } catch (err) {
        console.log(err);
      }
    }
    simpleContract();
  }, [contractAddr]);

  useEffect(() => {
    const getUserDetails = async () => {
      let _userDetails = {};
      const igoContractInstance = new web3.eth.Contract(
        igoLaunchpadABI,
        contractAddr
      );
      const usertierDetails = await igoContractInstance.methods
        .userDetails(web3Data.address)
        .call();
      _userDetails.tier = usertierDetails.tier;
      _userDetails.investedAmount = usertierDetails.investedAmount;
      // Get balance of connected address.
      // if (web3 && web3Data?.address) {
      _userDetails.balance = await web3.eth.getBalance(web3Data?.address);
      if (!singlePoolDetail?.isPaymentTokenNative && web3Data?.address) {
        const tokenContractInstance = new web3.eth.Contract(
          tokenABI,
          web3.utils.toChecksumAddress(singlePoolDetail.paymentTokenAddress)
        );
        _userDetails.allowance = web3.utils.fromWei(
          await tokenContractInstance.methods
            .allowance(web3Data?.address, contractAddr)
            .call()
        );

        _userDetails.totalSupply = web3.utils.fromWei(
          await tokenContractInstance.methods.totalSupply().call()
        );

        _userDetails.balance = await tokenContractInstance.methods
          .balanceOf(web3Data?.address)
          .call();
      }
      setTokenAllowance(_userDetails.allowance);
      setUserDetails(_userDetails);
      console.log("here it goes", _userDetails);
    };
    if (web3Data.address && contractAddr) getUserDetails();
    console.log("web3data", web3Data);
    settxloader(false);
  }, [web3Data, contractAddr, tokenAllowance]);

  useEffect(() => {
    setInterval(async function () {
      //Consuming smart contract ABI and contract address.
      if (contractAddr) {
        const SimpleContract = new web3.eth.Contract(
          singlePoolDetail?.isPaymentTokenNative ? BusdIDOabi : igoLaunchpadABI,
          contractAddr
        );
        //Getting total bnb from blockchain
        let totalTokenFxn = !singlePoolDetail?.isPaymentTokenNative
          ? "totalBUSDReceivedInAllTier"
          : "totalBnbReceivedInAllTier";

        const result = await SimpleContract.methods[totalTokenFxn]().call();
        //Getting max cap from blockchain
        const total = await SimpleContract.methods.maxCap().call();
        setNumber2(result / 10 ** 18);
        setNumber1((result / 10 ** 18 / (total / 10 ** 18)) * 100);
      }
    }, 20000);
  }, [contractAddr, singlePoolDetail?.isPaymentTokenNative]);

  //function for transaction from web3Data?.

  const transactionMetamask = async () => {
    try {
      // handleClose();
      // use web3 function to convert to wei , removing 10**18 multiplier
      const avalue = web3.utils.toWei(amount.toString());

      const provider = window.provider;

      const chainid = provider.chainId;
      //check for BSC Mainnet
      if (
        chainid === "0x38" ||
        chainid === 56 ||
        chainid === "0x61" ||
        chainid === 97
      ) {
        if (+userBalance < +avalue) {
          return toast.info(
            `Not enough ${singlePoolDetail?.paymentTokenSymbol} balance`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        const address = window.addressselected;

        //loader start before getting data.
        toast.info("Transaction in progress...", {
          position: toast.POSITION.TOP_CENTER,
        });
        if (!singlePoolDetail?.isPaymentTokenNative) {
          settxloader(true);
          let newWeb3 = new Web3(provider);
          const igoContractInstance = new newWeb3.eth.Contract(
            !singlePoolDetail?.isPaymentTokenNative
              ? igoLaunchpadABI
              : SeedifyFundsContractAbi,
            contractAddr
          );
          console.log(
            "i am here",
            web3,
            igoContractInstance.methods,
            avalue,
            web3Data.address
          );
          await igoContractInstance.methods
            .buyTokens(avalue)
            .send({ from: web3Data?.address })
            .on("transactionHash", (hash) => {})
            .on("receipt", (receipt) => {
              onReciept(receipt);
            })
            .on("error", (error) => {
              onError(error);
              setOpen(false);
            });
        } else {
          const txstatus = await web3.eth.sendTransaction({
            to: singlePoolDetail?.paymentTokenAddress,
            from: address,
            value: avalue,
          });
          if (txstatus.status) {
            // loader stop after getting data.
            onReciept(txstatus);
          } else {
            onError();
            setOpen(false);
          }
        }
      } else {
        //Clearing cache to get web3Data? connection pop up.
        await web3Modal.clearCachedProvider();
        toast.info("Please switch to Binance Smart Chain", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      settxloader(false);
      setOpen(false);
      return {
        connectedStatus: false,
        status: toast.error("Transaction reverted, try again.", {
          position: toast.POSITION.TOP_CENTER,
        }),
      };
    }
  };

  const onReciept = (txstatus) => {
    settxloader(false);
    var hash = `https://bscscan.com/tx/${txstatus.transactionHash}`;
    var label = "View your transaction";
    const CustomToastWithLink = () => (
      <div style={{ textAlign: "center" }}>
        Transaction confirmed! <br />
        {/* <Router>
          <Link
            target={"_blank"}
            style={{ color: "#fff", textDecoration: "underline" }}
            to={{ pathname: hash }}
          >
            {label}
          </Link>
        </Router> */}
      </div>
    );
    setOpen(false);
    toast.info(CustomToastWithLink, { position: toast.POSITION.TOP_CENTER });
  };
  const onError = () => {
    settxloader(false);
    setOpen(false);
    return toast.error("Transaction Failed!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const checktierswhitelist = async () => {
    let tier_value = +userdetails.tier;
    if (!web3Data?.address) {
      return toast.info("Your wallet is not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    //Switch cases according to tiers.
    // if (tier_value.tier > 0) {
    switch (tier_value) {
      case 1:
        setwhichtier(1);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[0]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[0]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[0]);
        setOpen(true);

        break;
      case 2:
        setwhichtier(2);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[1]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[1]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[1]);
        setOpen(true);
        break;
      case 3:
        setwhichtier(3);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[2]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[2]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[2]);
        setOpen(true);
        break;
      case 4:
        setwhichtier(4);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[3]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[3]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[3]);
        setOpen(true);
        break;
      case 5:
        setwhichtier(5);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[4]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[4]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[4]);
        setOpen(true);
        break;
      case 6:
        setwhichtier(6);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[5]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[5]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[5]);
        setOpen(true);
        break;
      case 7:
        setwhichtier(7);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[6]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[6]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[6]);
        setOpen(true);
        break;
      case 8:
        setwhichtier(8);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[7]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[7]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[7]);
        setOpen(true);
        break;
      case 9:
        setwhichtier(9);
        setTierMaxLimit(singlePoolDetail?.phases[0].maxTierCap[8]);
        setAmount(singlePoolDetail?.phases[0].maxUserCap[8]);
        setMinValueOne(singlePoolDetail?.phases[0].minUserCap[8]);
        setOpen(true);
        break;
      default:
        return toast.info("This wallet is not KYC verified for any of tiers", {
          position: toast.POSITION.TOP_CENTER,
        });
    }
    // } else {
    // 	return toast.info('Address does not match!', { position: toast.POSITION.TOP_CENTER })
    // }
  };

  if (singlePoolDetail?.start_date) {
    var closed = 0;
    var closesIn = 0;
    var startIn = 0;
    var filled = 0;
    var date = new Date();
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    var start = singlePoolDetail?.start_date;
    var end = singlePoolDetail?.end_date;
    if (number1 > "99.98") {
      startIn = 0;
      closesIn = 0;
      closed = 0;
      filled = 1;
      y = 1;
    } else if (end < now_utc) {
      closed = 1;
      y = 1;
    } else if (now_utc < start) {
      startIn = 1;
      y = 1;
    } else if (end >= now_utc && now_utc >= start) {
      closesIn = 1;
      y = 0;
    } else {
      startIn = 0;
      closesIn = 0;
      y = 1;
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const body = () => {
    // if (
    //   !singlePoolDetail?.isPaymentTokenNative &&
    //   +userdetails?.allowance == 0
    // ) {
    return (
      <div className="paper">
        <div className="paper-inner">
          <div className="paper-head">
            <h2 className="paper_h2" id="simple-modal-title">
              {!singlePoolDetail?.isPaymentTokenNative &&
              +userdetails?.allowance == 0 &&
              tokenAllowance == 0
                ? "Approve Token"
                : "Buy Token"}
            </h2>

            <span onClick={() => handleClose()}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          {txloader && <Loading></Loading>}
          {!txloader && (
            <div>
              {!singlePoolDetail?.isPaymentTokenNative &&
              +userdetails?.allowance == 0 &&
              tokenAllowance == 0 ? (
                <br />
              ) : (
                <div>
                  <p className="amt">
                    Enter amount in {singlePoolDetail?.paymentTokenSymbol} :{" "}
                  </p>
                  <input
                    className="paper_input "
                    type="number"
                    min="0"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              )}
              <Button
                className="paper_button primary"
                onClick={() =>
                  !singlePoolDetail?.isPaymentTokenNative &&
                  +userdetails?.allowance == 0 &&
                  tokenAllowance == 0
                    ? approveTokens()
                    : transactionMetamask()
                }
              >
                {!singlePoolDetail?.isPaymentTokenNative &&
                +userdetails?.allowance == 0 &&
                tokenAllowance == 0
                  ? "Approve"
                  : "Confirm"}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
    // } else {
    //   return (
    //     <div className="paper">
    //       <div className="paper-inner">
    //         <div className="paper-head">
    //           <h2 className="paper_h2" id="simple-modal-title">
    //             Buy Token
    //           </h2>
    //           <span onClick={handleClose}>
    //             <i className="fa fa-times" aria-hidden="true"></i>
    //           </span>
    //         </div>
    //         <p className="amt">
    //           Enter amount in {singlePoolDetail?.paymentTokenSymbol} :{" "}
    //         </p>
    //         <input
    //           className="paper_input "
    //           type="number"
    //           min="0"
    //           name="amount"
    //           value={amount}
    //           onChange={(e) => setAmount(e.target.value)}
    //         />{" "}
    //         <Button
    //           className="paper_button primary"
    //           onClick={() => transactionMetamask()}
    //         >
    //           Confirm
    //         </Button>
    //       </div>
    //     </div>
    //     //<br />
    //   );
    // }
  };
  const approveTokens = async () => {
    let newWeb3 = new Web3(window.provider);
    const tokenContractInstance = new newWeb3.eth.Contract(
      tokenABI,
      web3.utils.toChecksumAddress(singlePoolDetail.paymentTokenAddress)
    );
    // const { web3Data } = this.state;
    settxloader(true);
    await tokenContractInstance.methods
      .approve(
        contractAddr,
        web3.utils.toWei(userdetails.totalSupply).toString()
      )
      .send({ from: web3Data?.address })
      .on("transactionHash", (hash) => {
        // onTransactionHash(hash);
        console.log(hash);
      })
      .on("receipt", (receipt) => {
        setTokenAllowance(1);
        setOpen(open);
      })
      .on("error", (error) => {
        onError(error);
        setOpen(false);
      });
  };

  // const checkTierAddress = async () => {
  //   await getDataAPI(`csv_get/${[id, web3Data?.address]}`).then((res) =>
  //     checktierswhitelist(res.data)
  //   );
  // };
  const [startTime, setStartTime] = useState("");
  const [startTimeMobile, setStartTimeMobile] = useState("");

  //setting time counter.
  setInterval(function () {
    var date = new Date();
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    var closes_in_days = "";
    var closes_in_hours = "";
    var closes_in_minutes = "";
    var closes_seconds = "";
    var desktopTimer = "";
    var mobileTimer = "";
    var closes_in_sec = "";
    if (singlePoolDetail?.start_date && startIn) {
      var start = singlePoolDetail?.start_date;
      closes_in_sec = (start - now_utc) / 1000;

      closes_in_days = Math.floor(closes_in_sec / (3600 * 24));

      closes_in_sec -= closes_in_days * 86400;

      closes_in_hours = Math.floor(closes_in_sec / 3600) % 24;
      closes_in_sec -= closes_in_hours * 3600;

      closes_in_minutes = Math.floor(closes_in_sec / 60) % 60;
      closes_in_sec -= closes_in_minutes * 60;

      closes_seconds = Math.floor(closes_in_sec % 60);

      desktopTimer = `${closes_in_days} days: ${closes_in_hours} hours: ${closes_in_minutes} minutes: ${closes_seconds} seconds`;
      mobileTimer = `${closes_in_days} d: ${closes_in_hours} h: ${closes_in_minutes} m: ${closes_seconds} s`;

      if (
        closes_in_days === 0 &&
        closes_in_hours === 0 &&
        closes_in_minutes === 0 &&
        closes_seconds === 2
      ) {
        window.location.reload();
      }
      setStartTime(desktopTimer);
      setStartTimeMobile(mobileTimer);
    }

    if (singlePoolDetail?.end_date && closesIn) {
      var end = singlePoolDetail?.end_date;
      closes_in_sec = (end - now_utc) / 1000;

      closes_in_days = Math.floor(closes_in_sec / (3600 * 24));

      closes_in_sec -= closes_in_days * 86400;

      closes_in_hours = Math.floor(closes_in_sec / 3600) % 24;
      closes_in_sec -= closes_in_hours * 3600;

      closes_in_minutes = Math.floor(closes_in_sec / 60) % 60;
      closes_in_sec -= closes_in_minutes * 60;

      closes_seconds = Math.floor(closes_in_sec % 60);

      desktopTimer = `${closes_in_days} days: ${closes_in_hours} hours: ${closes_in_minutes} minutes: ${closes_seconds} seconds`;
      mobileTimer = `${closes_in_days}d: ${closes_in_hours}h: ${closes_in_minutes}m: ${closes_seconds}s`;
      if (
        closes_in_days === 0 &&
        closes_in_hours === 0 &&
        closes_in_minutes === 0 &&
        closes_seconds === 2
      ) {
        window.location.reload();
      }
      setStartTime(desktopTimer);
      setStartTimeMobile(mobileTimer);
    }
  }, 1000);

  var allocation = totalPoolRaise * singlePoolDetail?.up_pool_raise;
  var num = Math.ceil(
    (totalPoolRaise / singlePoolDetail?.targetPoolRaise) * 100
  );
  var full = "";
  if (num === 50) {
    full = "fullupload";
  }
  return (
    <div>
      <div className="pool_detail_banner">
        <div className="container_cust">
          <div className="inner_pool_detail_banner">
            <div className="left_ban">
              <div className="ti_tle">
                <img src={singlePoolDetail?.imageURL} alt="" />
                <div className="socia_grd">
                  <div>
                    <h3>{singlePoolDetail?.igoName}</h3>

                    <p>{singlePoolDetail?.content}</p>
                  </div>
                  <div className="socia_l">
                    <ul>
                      {singlePoolDetail?.socialLinks.twitter ? (
                        <li className="nav-item ">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </span>{" "}
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.facebook ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <i
                                className="fa fa-medium"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.telegram ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.telegram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <TelegramIcon />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.browser_web ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.browser_web}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <LanguageIcon />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.youtube ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <YouTubeIcon />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.instagram ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <InstagramIcon />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {singlePoolDetail?.socialLinks.discord ? (
                        <li className="nav-item ">
                          <a
                            className="nav-link"
                            href={singlePoolDetail?.socialLinks.discord}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <i
                                className="fa fa-discord"
                                area-hidden="true"
                              ></i>
                            </span>{" "}
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_ban">
              <div className="detail_card_outer">
                <div className="detail_card">
                  <div className="tit_row">
                    <div className="tit_le2">
                      {/* <img src={bitcoin} alt="" /> */}
                      <h3>
                        <div className="pooldetail-title">
                          {singlePoolDetail?.paymentTokenSymbol} Coin{" "}
                        </div>
                        <span>
                          {" "}
                          1 {singlePoolDetail?.paymentTokenSymbol} ={" "}
                          {singlePoolDetail?.price}{" "}
                          {singlePoolDetail?.igoTokenSymbol}{" "}
                        </span>
                      </h3>
                    </div>
                  </div>

                  <div className="allocation">
                    <div>
                      <p>Total Raised</p>
                      <div className="timer_desktop">
                        <h3 style={{ color: "white", fontSize: 18 }}>
                          {totalPoolRaise ? totalPoolRaise.toFixed(4) : "0"}{" "}
                          {singlePoolDetail?.paymentTokenSymbol}
                        </h3>
                      </div>
                      <div className="timer_mobile">
                        <h3 style={{ color: "white", fontSize: 14 }}>
                          {totalPoolRaise ? totalPoolRaise.toFixed(4) : "0"}{" "}
                          {singlePoolDetail?.paymentTokenSymbol}
                        </h3>
                      </div>
                    </div>
                    <div className="rts">
                      {startIn ? <p className="status-p">Start in</p> : ""}
                      <div className="timer_desktop">
                        {startIn === 1 ? (
                          <h3
                            style={{ color: "white", fontSize: 18 }}
                            id="poolonpagestart"
                          >
                            {startTime}
                          </h3>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="timer_mobile">
                        {startIn === 1 ? (
                          <h3
                            style={{ color: "white", fontSize: 14 }}
                            id="poolonpagestart"
                          >
                            {startTimeMobile}
                          </h3>
                        ) : (
                          ""
                        )}
                      </div>
                      {closesIn ? <p className="status-p">Ends in</p> : ""}
                      <div className="timer_desktop">
                        {closesIn === 1 ? (
                          <h3
                            style={{ color: "white", fontSize: 18 }}
                            id="poolonpagestart"
                          >
                            {startTimeMobile}
                          </h3>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="timer_mobile">
                        {closesIn === 1 ? (
                          <h3
                            style={{ color: "white", fontSize: 14 }}
                            id="poolonpagestart"
                          >
                            {startTimeMobile}
                          </h3>
                        ) : (
                          ""
                        )}
                      </div>
                      {closed ? <p className="status-p">Status</p> : ""}
                      {closed ? <h3>Closed</h3> : ""}
                      {filled ? <h3>Filled</h3> : ""}
                    </div>
                  </div>

                  <div className="prog_bar">
                    <div className="prog_bar_grd">
                      <span className="prog _percent">
                        <p>Progress</p>{" "}
                        {totalPoolRaise
                          ? (
                              (totalPoolRaise /
                                singlePoolDetail.targetPoolRaise) *
                              100
                            ).toFixed(2)
                          : "0"}
                        %
                      </span>
                      {
                        <span className="parti _nls">
                          {totalPoolRaise
                            ? (
                                totalPoolRaise * singlePoolDetail?.price
                              ).toFixed(2)
                            : "0"}
                          /
                          {singlePoolDetail?.targetPoolRaise *
                            singlePoolDetail?.price}{" "}
                          {/* {singlePoolDetail?.total_supply?.$numberDecimal}{" "} */}
                          {singlePoolDetail?.igoTokenSymbol}
                        </span>
                      }
                    </div>
                    {/* <div className="progress">
										<div className="bar" style={{ width: `${number1}%` }}>
											<p className="percent">{number1}</p>
										</div>
									</div> */}
                    <div className={`battery ${full}`}>
                      {num
                        ? [...Array(num)].map(() => (
                            <div className="bar active" data-power="10"></div>
                          ))
                        : ""}
                    </div>
                  </div>
                  <div className="buy-btnbtc">
                    {/* <div className="buy-token"> */}
                    {y ? (
                      ""
                    ) : (
                      <Button
                        className="primary"
                        onClick={() => checktierswhitelist()}
                      >
                        Buy Tokens
                      </Button>
                    )}
                    {/* </div> */}
                    <div className="prog_bar_grd">
                      <span className="parti">
                        <p>Limited</p> Participants{" "}
                        {/* {singlePoolDetail?.participants} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Modal for transaction amount input. */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body()}
        </Modal>
      </div>
      <PoolInformation singlePoolDetail={singlePoolDetail} />
      <AboutProject singlePoolDetail={singlePoolDetail} />
      <ToastContainer
        position="bottom-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
      {/* <Footer /> */}
    </div>
  );
};

const mapDipatchToProps = (dispatch) => {
  return {
    getSinglePoolDetail: (id) => dispatch(actions.getSinglePoolDetail(id)),
    getUpcomingIGOPools: () => dispatch(actions.getUpcomingIGOPools()),
  };
};

const mapStateToProps = (state) => {
  return {
    singlePoolDetail: state.singlePoolDetail,
    upcomingIGOPools: state.upcomingIGOPools,
    web3Data: state.web3Data,
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(PoolDetail);
