import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import CONSTANT from "./constant";
// import * as solanaWeb3 from "@solana/web3.js";
// const isMathWalletInstalled = window.solana && window.solana.isMathWallet;
let web3 = null;
const walletConnectProvider = new WalletConnectProvider({
  rpc: {
    0x38: "https://speedy-nodes-nyc.moralis.io/e280a1853045f7d20dcb71e1/bsc/mainnet", // BSC Mainnet chainId - 56
    // 0x61: 'https://data-seed-prebsc-1-s1.binance.org:8545/', // BSC Testnet chainId - 97
    0x89: "https://speedy-nodes-nyc.moralis.io/e280a1853045f7d20dcb71e1/polygon/mainnet",
    0x1: "https://speedy-nodes-nyc.moralis.io/e280a1853045f7d20dcb71e1/eth/mainnet",
    "0xA86A":
      "https://speedy-nodes-nyc.moralis.io/e280a1853045f7d20dcb71e1/avalanche/mainnet",
  },
  qrcode: false,
});

const mathWalletConnectInit = () => {
  if ("solana" in window) {
    const provider = window.solana;
    if (provider.isMathWallet) {
      return (web3 = provider);
    }
  }
  window.open("https://mathwallet.org/", "_blank");
};

const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise((resolve, reject) => {
    if (typeof window.web3 !== "undefined") {
      // Use Mist/MetaMask's provider.
      web3 = new Web3(window.web3.currentProvider);
      localStorage.setItem("walletConnect", 0);
      resolve(true);
    } else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.
      web3 = new Web3(
        new Web3.providers.HttpProvider(
          CONSTANT.ENV === "DEVELOPMENT"
            ? "https://bsc-testnet.public.blastapi.io/"
            : "https://bsc-dataseed2.binance.org/"
          // "https://data-seed-prebsc-1-s1.binance.org:8545/"
        )
      );
      reject(false);
    }
  });
};

const walletConnectInit = () => {
  // Check if WalleConnect has been conected by the website
  return new Promise((resolve, reject) => {
    if (!walletConnectProvider.connector.connected) {
      metamaskConnectInit();
      reject(false);
    } else {
      // Use WalletConnect provider.
      walletConnectProvider.enable();
      web3 = new Web3(walletConnectProvider);
      resolve(true);
    }
  });
};

const walletConnectModalInit = () => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("walletConnect", 1);
    walletConnectProvider.enable();
    web3 = new Web3(walletConnectProvider);
    // Wallet Connect Provider Events
    walletConnectProvider.connector.on("display_uri", (err, payload) => {
      const uri = payload.params[0];
      WalletConnectQRCodeModal.open(uri);
    });
    walletConnectProvider.on("connect", () => {
      WalletConnectQRCodeModal.close(); // close the QR scanner modal
    });
    walletConnectProvider.on("disconnect", (code, reason) => {
      localStorage.clear();
    });
    resolve(true);
  });
};

// const walletConnectInit = async () => {
//   localStorage.setItem("walletConnect", 1);
//   walletConnectProvider.enable();
//   web3 = new Web3(walletConnectProvider);
//   if (!walletConnectProvider.connector.connected) {
//     web3 = new Web3(
//       new Web3.providers.HttpProvider(
//         "https://mainnet.infura.io/v3/de21e440aade484290be1e3c89e67f28"
//         // "https://rinkeby.infura.io/v3/de21e440aade484290be1e3c89e67f28"
//       )
//     );
//   }
// };

// const walletConnectModalInit = async () => {
//   return new Promise(async (resolve, reject) => {
//     walletConnectInit();
//     const uri = walletConnectProvider.connector.uri;
//     if (!walletConnectProvider.connector.connected) {
//       WalletConnectQRCodeModal.open(uri);
//     } else resolve(true);
//   });
// };

const phantomWalletConnectInit = () => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("walletConnect", 2);
    if ("solana" in window) {
      const anyWindow = window;
      const provider = anyWindow.solana;
      if (provider?.isPhantom) {
        web3 = provider;
        resolve(true);
      } else window.open("https://phantom.app/", "_blank");
    }
  });
};

if (!web3) {
  if (Number(localStorage.getItem("walletConnect")) === 1) {
    walletConnectInit();
  } else if (Number(localStorage.getItem("walletConnect")) === 2) {
    phantomWalletConnectInit();
  } else metamaskConnectInit();
}

export {
  web3,
  walletConnectProvider,
  walletConnectInit,
  metamaskConnectInit,
  walletConnectModalInit,
  mathWalletConnectInit,
  phantomWalletConnectInit,
};
